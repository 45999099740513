@-webkit-keyframes bounceInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-200px);
    }
    60% {
        opacity: 1;
        -webkit-transform: translateX(30px);
    }
    80% {
        -webkit-transform: translateX(-10px);
    }
    100% {
        -webkit-transform: translateX(0);
    }
}

@keyframes bounceInLeft {
    0% {
        opacity: 0;
        transform: translateX(-200px);
    }
    60% {
        opacity: 1;
        transform: translateX(30px);
    }
    80% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}

.slideContainer_mcr {
    display: none;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
}

.slideContainer_mcr.actividad {
    display: block;
}

.slideContainer_mcr.actividad {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft;
}

.slideButton_mcr.actividad {
    background-color: #0471C5;
    cursor: pointer;
}

.newArrowLeft:before {
    overflow: visible;
    font-family: FontAwesome;
    content: "\f104";
    font-size: 16px;
}