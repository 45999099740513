.card.safari.identified .front:before, .card.safari.identified .back:before {
  background-image: -webkit-repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-linear-gradient(315deg, rgba(255, 255, 255, 0.05) 1px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.03) 4px), -webkit-repeating-linear-gradient(0deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-linear-gradient(240deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-linear-gradient(-245deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%);
  background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-linear-gradient(135deg, rgba(255, 255, 255, 0.05) 1px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.03) 4px), repeating-linear-gradient(90deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-linear-gradient(210deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-linear-gradient(-245deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%);
  background-image: -webkit-repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-linear-gradient(315deg, rgba(255, 255, 255, 0.05) 1px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.03) 4px), -webkit-repeating-linear-gradient(0deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-linear-gradient(240deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-linear-gradient(115deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%);
  background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-linear-gradient(135deg, rgba(255, 255, 255, 0.05) 1px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.03) 4px), repeating-linear-gradient(90deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-linear-gradient(210deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), linear-gradient(-25deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%); }

.card.ie-10.flipped, .card.ie-11.flipped {
  -webkit-transform: 0deg;
  -ms-transform: 0deg;
  transform: 0deg; }
  .card.ie-10.flipped .front, .card.ie-11.flipped .front {
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg); }
  .card.ie-10.flipped .back, .card.ie-11.flipped .back {
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg); }
    .card.ie-10.flipped .back:after, .card.ie-11.flipped .back:after {
      left: 18%; }
    .card.ie-10.flipped .back .cvc, .card.ie-11.flipped .back .cvc {
      -webkit-transform: rotateY(180deg);
      -ms-transform: rotateY(180deg);
      transform: rotateY(180deg);
      left: 5%; }
    .card.ie-10.flipped .back .shiny, .card.ie-11.flipped .back .shiny {
      left: 84%; }
      .card.ie-10.flipped .back .shiny:after, .card.ie-11.flipped .back .shiny:after {
        left: -480%;
        -webkit-transform: rotateY(180deg);
        -ms-transform: rotateY(180deg);
        transform: rotateY(180deg); }

.card.ie-10.amex .back, .card.ie-11.amex .back {
  display: none; }

.card-logo {
  height: 36px;
  width: 60px;
  font-style: italic; }
  .card-logo, .card-logo:before, .card-logo:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.card-logo.amex {
  text-transform: uppercase;
  font-size: 4px;
  font-weight: bold;
  color: white;
  background-image: -webkit-repeating-radial-gradient(center, circle, #FFF 1px, #999 2px);
  background-image: repeating-radial-gradient(circle at center, #FFF 1px, #999 2px);
  background-image: repeating-radial-gradient(circle at center, #FFF 1px, #999 2px);
  border: 1px solid #EEE; }
  .card-logo.amex:before, .card-logo.amex:after {
    width: 28px;
    display: block;
    position: absolute;
    left: 16px; }
  .card-logo.amex:before {
    height: 28px;
    content: "american";
    top: 3px;
    text-align: left;
    padding-left: 2px;
    padding-top: 11px;
    background: #267AC3; }
  .card-logo.amex:after {
    content: "express";
    bottom: 11px;
    text-align: right;
    padding-right: 2px; }

.card.amex.flipped {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none; }
.card.amex.identified .front:before, .card.amex.identified .back:before {
  background-color: #108168; }
.card.amex.identified .front .card-logo.amex {
  opacity: 1; }
.card.amex.identified .front .cvc {
  visibility: visible; }
.card.amex.identified .front:after {
  opacity: 1; }

.card-logo.discover {
  background: #FF6600;
  color: #111;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  padding-top: 9px;
  letter-spacing: 0.03em;
  border: 1px solid #EEE; }
  .card-logo.discover:before, .card-logo.discover:after {
    content: " ";
    display: block;
    position: absolute; }
  .card-logo.discover:before {
    background: white;
    width: 200px;
    height: 200px;
    border-radius: 200px;
    bottom: -5%;
    right: -80%;
    z-index: -1; }
  .card-logo.discover:after {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    top: 10px;
    left: 27px;
    background-color: #FFF;
    background-image: -webkit-radial-gradient(#FFF, #FF6600);
    background-image: radial-gradient(  #FFF, #FF6600);
    content: "network";
    font-size: 4px;
    line-height: 24px;
    text-indent: -7px; }

.card .front .card-logo.discover {
  right: 12%;
  top: 18%; }

.card.discover.identified .front:before, .card.discover.identified .back:before {
  background-color: #86B8CF; }
.card.discover.identified .card-logo.discover {
  opacity: 1; }
.card.discover.identified .front:after {
  -webkit-transition: 400ms;
  transition: 400ms;
  content: " ";
  display: block;
  background-color: #FF6600;
  background-image: -webkit-linear-gradient(#FF6600, #ffa166, #FF6600);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FF6600), color-stop(#ffa166), to(#FF6600));
  background-image: linear-gradient(#FF6600, #ffa166, #FF6600);
  height: 50px;
  width: 50px;
  border-radius: 25px;
  position: absolute;
  left: 100%;
  top: 15%;
  margin-left: -25px;
  -webkit-box-shadow: inset 1px 1px 3px 1px rgba(0, 0, 0, 0.5);
  box-shadow: inset 1px 1px 3px 1px rgba(0, 0, 0, 0.5); }

.card-logo.visa.visa {
  background: white;
  text-transform: uppercase;
  color: #1A1876;
  text-align: center;
  font-weight: bold;
  font-size: 15px; }
  .card-logo.visa.visa:before, .card-logo.visa.visa:after {
    content: " ";
    display: block;
    width: 100%;
    height: 25%; }
  .card-logo.visa.visa:before {
    background: #1A1876;
    bottom: 100%; }
  .card-logo.visa.visa:after {
    background: #E79800;
    top: 100%; }

.card.visa.identified .front:before, .card.visa.identified .back:before {
  background-color: #191278; }
.card.visa.identified .card-logo.visa {
  opacity: 1; }

.card-logo.mastercard {
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 9px;
  line-height: 36px;
  z-index: 1;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.6); }
  .card-logo.mastercard:before, .card-logo.mastercard:after {
    content: " ";
    display: block;
    width: 36px;
    top: 0;
    position: absolute;
    height: 36px;
    border-radius: 18px; }
  .card-logo.mastercard:before {
    left: 0;
    background: #FF0000;
    z-index: -1; }
  .card-logo.mastercard:after {
    right: 0;
    background: #FFAB00;
    z-index: -2; }

.card.mastercard.identified .front .card-logo.mastercard, .card.mastercard.identified .back .card-logo.mastercard {
  -webkit-box-shadow: none;
  box-shadow: none; }
.card.mastercard.identified .front:before, .card.mastercard.identified .back:before {
  background-color: #0061A8; }
.card.mastercard.identified .card-logo.mastercard {
  opacity: 1; }

.card-container {
  -webkit-perspective: 1000px;
  perspective: 1000px;
  width: 350px;
  max-width: 100%;
  height: 200px;
  margin: auto;
  z-index: 1;
  position: relative; }

.card {
  font-family: "Helvetica Neue";
  line-height: 1;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 315px;
  border-radius: 10px;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear; }
  .card > *, .card > *:before, .card > *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: inherit; }
  .card.flipped {
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg); }
  .card .front, .card .back {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    border-radius: 10px;
    background: #DDD; }
    .card .front:before, .card .back:before {
      content: " ";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      border-radius: 10px;
      -webkit-transition: all 400ms ease;
      transition: all 400ms ease; }
    .card .front:after, .card .back:after {
      content: " ";
      display: block; }
    .card .front .display, .card .back .display {
      color: white;
      font-weight: normal;
      opacity: 0.5;
      -webkit-transition: opacity 400ms linear;
      transition: opacity 400ms linear; }
      .card .front .display.focused, .card .back .display.focused {
        opacity: 1;
        font-weight: 700; }
    .card .front .cvc, .card .back .cvc {
      font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
      font-size: 14px; }
    .card .front .shiny, .card .back .shiny {
      width: 50px;
      height: 35px;
      border-radius: 5px;
      background: #CCC;
      position: relative; }
      .card .front .shiny:before, .card .back .shiny:before {
        content: " ";
        display: block;
        width: 70%;
        height: 60%;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background: #d9d9d9;
        position: absolute;
        top: 20%; }
  .card .front .card-logo {
    position: absolute;
    opacity: 0;
    right: 5%;
    top: 8%;
    -webkit-transition: 400ms;
    transition: 400ms; }
  .card .front .lower {
    width: 80%;
    position: absolute;
    left: 10%;
    bottom: 30px; }
    @media only screen and (max-width: 480px) {
      .card .front .lower {
        width: 90%;
        left: 5%; } }
    .card .front .lower .cvc {
      visibility: hidden;
      float: right;
      position: relative;
      bottom: 5px; }
    .card .front .lower .number {
      font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
      font-size: 24px;
      clear: both;
      margin-bottom: 30px; }
    .card .front .lower .expiry {
      font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
      letter-spacing: 0em;
      position: relative;
      float: right;
      width: 25%; }
      .card .front .lower .expiry:before, .card .front .lower .expiry:after {
        font-family: "Helvetica Neue";
        font-weight: bold;
        font-size: 7px;
        white-space: pre;
        display: block;
        opacity: 0.5; }
      .card .front .lower .expiry:before {
        content: attr(data-before);
        margin-bottom: 2px;
        font-size: 7px;
        text-transform: uppercase; }
      .card .front .lower .expiry:after {
        position: absolute;
        content: attr(data-after);
        text-align: right;
        right: 100%;
        margin-right: 5px;
        margin-top: 2px;
        bottom: 0; }
    .card .front .lower .name {
      text-transform: uppercase;
      font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
      font-size: 20px;
      max-height: 45px;
      position: absolute;
      bottom: 0;
      width: 190px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: horizontal;
      overflow: hidden;
      text-overflow: ellipsis; }
  .card .back {
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg); }
    .card .back .bar {
      background-color: #444;
      background-image: -webkit-linear-gradient(#444, #333);
      background-image: -webkit-gradient(linear, left top, left bottom, from(#444), to(#333));
      background-image: linear-gradient(#444, #333);
      width: 100%;
      height: 20%;
      position: absolute;
      top: 10%; }
    .card .back:after {
      content: " ";
      display: block;
      background-color: #FFF;
      background-image: -webkit-linear-gradient(#FFF, #FFF);
      background-image: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#FFF));
      background-image: linear-gradient(#FFF, #FFF);
      width: 80%;
      height: 16%;
      position: absolute;
      top: 40%;
      left: 2%; }
    .card .back .cvc {
      position: absolute;
      top: 40%;
      left: 85%;
      -webkit-transition-delay: 600ms;
      transition-delay: 600ms; }
    .card .back .shiny {
      position: absolute;
      top: 66%;
      left: 2%; }
      .card .back .shiny:after {
        content: "This card has been issued by Jesse Pollak and is licensed for anyone to use anywhere for free.\AIt comes with no warranty.\A For support issues, please visit: github.com/jessepollak/card.";
        position: absolute;
        left: 120%;
        top: 5%;
        color: white;
        font-size: 7px;
        width: 230px;
        opacity: 0.5; }
  .card.identified {
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); }
    .card.identified .front, .card.identified .back {
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.5); }
      .card.identified .front:before, .card.identified .back:before {
        -webkit-transition: all 400ms ease;
        transition: all 400ms ease;
        background-image: -webkit-repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-linear-gradient(315deg, rgba(255, 255, 255, 0.05) 1px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.03) 4px), -webkit-repeating-linear-gradient(0deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-linear-gradient(240deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-radial-gradient(30% 30%, circle, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-radial-gradient(70% 70%, circle, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-radial-gradient(90% 20%, circle, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-radial-gradient(15% 80%, circle, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-linear-gradient(-245deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%);
        background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-linear-gradient(135deg, rgba(255, 255, 255, 0.05) 1px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.03) 4px), repeating-linear-gradient(90deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-linear-gradient(210deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-radial-gradient(circle at 70% 70%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-radial-gradient(circle at 90% 20%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-radial-gradient(circle at 15% 80%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-linear-gradient(-245deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%);
        background-image: -webkit-repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-linear-gradient(315deg, rgba(255, 255, 255, 0.05) 1px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.03) 4px), -webkit-repeating-linear-gradient(0deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-linear-gradient(240deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-radial-gradient(30% 30%, circle, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-radial-gradient(70% 70%, circle, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-radial-gradient(90% 20%, circle, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-radial-gradient(15% 80%, circle, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-linear-gradient(115deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%);
        background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-linear-gradient(135deg, rgba(255, 255, 255, 0.05) 1px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.03) 4px), repeating-linear-gradient(90deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-linear-gradient(210deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-radial-gradient(circle at 70% 70%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-radial-gradient(circle at 90% 20%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-radial-gradient(circle at 15% 80%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), linear-gradient(-25deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%);
        opacity: 1; }
      .card.identified .front .card-logo, .card.identified .back .card-logo {
        -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3);
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3); }
    .card.identified.no-radial-gradient .front:before, .card.identified.no-radial-gradient .back:before {
      background-image: -webkit-repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-linear-gradient(315deg, rgba(255, 255, 255, 0.05) 1px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.03) 4px), -webkit-repeating-linear-gradient(0deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-linear-gradient(240deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-linear-gradient(-245deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%);
      background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-linear-gradient(135deg, rgba(255, 255, 255, 0.05) 1px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.03) 4px), repeating-linear-gradient(90deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-linear-gradient(210deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-linear-gradient(-245deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%);
      background-image: -webkit-repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-linear-gradient(315deg, rgba(255, 255, 255, 0.05) 1px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.03) 4px), -webkit-repeating-linear-gradient(0deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-repeating-linear-gradient(240deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-linear-gradient(115deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%);
      background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-linear-gradient(135deg, rgba(255, 255, 255, 0.05) 1px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.03) 4px), repeating-linear-gradient(90deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), repeating-linear-gradient(210deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), linear-gradient(-25deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%); }
